import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Highlights from '../components/shared/highlights/Highlights';
import { FinishInfo, Slab, Material } from '../components/shared/interfaces';
import { Modal } from '../components/shared/modal/Modal';
import ProductContainer from '../components/shared/product-container/ProductContainer';
import CollapseFiltersSideBar from '../components/slab/collapse-filters-side-bar/CollapseFiltersSideBar';
import { FilterType } from '../components/slab/collapse-filters-side-bar/filter-type.enum';
import MobileFiltersBtn from '../components/slab/mobile-filters/mobile-filters-btn/MobileFiltersBtn';
import MobileFilters from '../components/slab/mobile-filters/MobileFilters';
import {
  applyFilters,
  createSortFn,
  handleFilterUpdate,
  loadFilterState,
  saveFilterState,
} from '../components/slab/sort-filter/filter-utils';
import SortFilter from '../components/slab/sort-filter/SortFilter';
import { TileState } from '../components/tile/tile-state.interface';
import useFavourite from '../hooks/useFavourite';
import useViewport from '../hooks/useViewport';
import styles from './slab.module.scss';

export const query = graphql`
  query {
    strapi {
      materials {
        id
        name
        sort_index
      }
      slabPage {
        backgroundImage {
          alternativeText
          url
        }
        subtitle
        description
        title
      }
      slabs {
        ExternalUrl
        created_at
        isSaleSlab: SaleSlabs
        id
        color_primary {
          id
          name
        }
        color_secondary {
          id
          name
        }
        color_tertiary {
          id
          name
        }
        material {
          id
          name
        }
        finish {
          id
          name
        }
        images {
          url
        }
        thickness
        description
        name
        size
        preview {
          url
        }
      }
      finishes {
        name
        id
        is_textured
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      slabs: Slab[];
      finishes: FinishInfo[];
      materials: Material[];
    };
  };
}

let PAGE_SIZE = 12;
let CHANGE_PAGE_SIZE = false;

const SlabPage: React.FC<Props> = ({
  data: {
    strapi: { finishes, slabs, materials, slabPage },
  },
}: Props) => {
  const initialSlabs = formatData(slabs, finishes, 'A - Z');
  const { isTablet } = useViewport();
  const favouriteAPI = useFavourite();

  const setOffset = () => {
    setTimeout(() => {
      window.scrollTo({
        top: +window.sessionStorage.getItem('lastOffset'),
        behavior: 'instant',
      });
    }, 0);
  };

  useEffect(() => {
    // window.addEventListener('popstate', setOffset, false);
    return () => {
      window.sessionStorage.setItem('lastOffset', window.scrollY);
    };
  }, []);

  const pageName = 'Slab';

  const filtersStoredState = loadFilterState(pageName);

  if (typeof window !== 'undefined' && window.location.hash) {
    const decodedHash = window.atob(window.location?.hash.substring(1));

    const parsedDecodedHash = JSON.parse(decodedHash);

    try {
      filtersStoredState.filters = parsedDecodedHash.filtersState;
      filtersStoredState.sortState = parsedDecodedHash.sortState;
    } catch (e) {
      filtersStoredState.filters = filtersStoredState.filters;
      filtersStoredState.sortState = filtersStoredState.sortState;
    }
  }

  const initialState: TileState = {
    isFilterPopupOpen: false,
    filtersState: filtersStoredState.filters,
    tiles: initialSlabs,
    loadedTiles: initialSlabs.slice(0, PAGE_SIZE),
    sortState: filtersStoredState.sortState,
  };

  applyFilters(initialSlabs, (t) => (initialState.tiles = t.tiles), initialState);

  const [state, setState] = useState<TileState>(initialState);

  const updateState = (newState: TileState) => {
    const objToStringify = { sortState: {}, filtersState: {} };
    objToStringify.sortState = newState.sortState;
    objToStringify.filtersState = newState.filtersState;

    window.location.hash = btoa(JSON.stringify(objToStringify));

    saveFilterState(pageName, newState.filtersState, newState.sortState);
    setState(newState);
  };

  const switchFilterPopup = (): void =>
    updateState({ ...state, isFilterPopupOpen: !state.isFilterPopupOpen });

  const clearFilter = (
    filter?: FilterType.MATERIAL | FilterType.FINISH | FilterType.COLOR | FilterType.DIMENSIONS,
  ) => {
    if (filter) {
      state.filtersState[filter] = [];
    } else {
      state.filtersState = {
        [FilterType.IS_IN_STOCK]: false,
        [FilterType.IS_DEALER]: false,
        [FilterType.SEARCH]: '',
        [FilterType.COLOR]: [],
        [FilterType.MATERIAL]: [],
        [FilterType.FINISH]: [],
        [FilterType.DIMENSIONS]: [],
      };
    }

    applyFilters(initialSlabs, updateState, state);
  };

  const loadMore = (quantity: number) =>
    updateState({
      ...state,
      loadedTiles: state.tiles.slice(0, state.loadedTiles.length + quantity),
    });

  useEffect(() => {
    window.addEventListener('popstate', setOffset, false);
    updateState({ ...state, loadedTiles: state.tiles.slice(0, PAGE_SIZE) });
    return function () {
      // PAGE_SIZE = state.loadedTiles.length || 12;
      PAGE_SIZE =
        !state.loadedTiles.length || state.loadedTiles.length < 12 ? 12 : state.loadedTiles.length;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tiles]);

  {
    if (typeof window !== 'undefined') {
      window.onpopstate = () => {
        // PAGE_SIZE = state.loadedTiles.length || 12;
        PAGE_SIZE =
          !state.loadedTiles.length || state.loadedTiles.length < 12
            ? 12
            : state.loadedTiles.length;
        CHANGE_PAGE_SIZE = true;
      };
    }
  }

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <Layout
      isWhiteBackground={true}
      bottomFloatingComponent={
        isTablet && <MobileFiltersBtn name={'FILTER'} onClick={switchFilterPopup} />
      }
    >
      <SEO title={slabPage.title} />
      <section className={styles.wrapper}>
        <section
          className={styles.highlightContainer}
          style={{
            backgroundImage: `url(${slabPage?.backgroundImage.url})`,
          }}
        >
          <Highlights
            title={slabPage.title}
            subtitle={slabPage.subtitle}
            description={slabPage.description}
            subtitleWidth="55vw"
            onDark={true}
            textColor="#fff"
            hideDelimiter={true}
            fontFamily="Sainte Colombe, Helvetica, sans-serif"
            addClassName="slab-tile-highlight-section"
            fontWeight="400"
          />
        </section>
        <div className={styles.topFilter}>
          <SortFilter
            sortState={state.sortState}
            updateSortState={(update) =>
              (state.sortState = update) && applyFilters(initialSlabs, updateState, state)
            }
          />
        </div>
        <section className={styles.content}>
          <div className={styles.filters}>
            <CollapseFiltersSideBar
              slab
              isSaleSlabs={true}
              materials={materials}
              tiles={initialSlabs}
              isRenderFinish={true}
              filtersState={state.filtersState}
              switchSelection={(filterType: FilterType, param: string, value?: string) =>
                handleFilterUpdate(filterType, param, initialSlabs, updateState, state, value)
              }
            />

            <div className={styles.text}>
              Did you spot the perfect slab on our website? If something catches your eye, you can
              easily arrange to see it in person or virtually. We update our online inventory
              regularly, so everything you see here is in stock and readily available.
            </div>
          </div>

          <InfiniteScroll
            dataLength={state.loadedTiles.length}
            next={() => loadMore(PAGE_SIZE)}
            className={styles.slabCardsContainer}
            hasMore={state.loadedTiles.length !== state.tiles.length}
            loader={<h4>Loading...</h4>}
          >
            {state.loadedTiles.length ? (
              state.loadedTiles.map((slab) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // prettier-ignore
                // <ProductContainer {...slab} externalLink={slab.ExternalUrl !== null ? slab.ExternalUrl : null}    isDecorative={slab.ExternalUrl !== null ? true : false} favouriteAPI={favouriteAPI} preview={{ url: slab.preview.url }} key={slab.id} path={`/slab/${slab.id}`} slab/>,
                <ProductContainer {...slab} externalLink={slab.ExternalUrl !== null ? slab.ExternalUrl : null}    isDecorative={slab.ExternalUrl !== null ? true : false} favouriteAPI={favouriteAPI} preview={{ url: `${slab.preview?.url.split('upload')[0]}${'upload/f_auto'}${slab.preview?.url.split('upload')[1]}` }} key={slab.id} path={`/slab/${slab.id}`} slab/>,
              )
            ) : (
              <div className={styles.cardPlaceholder}></div>
            )}
          </InfiniteScroll>
        </section>
      </section>

      <Modal isOpen={state.isFilterPopupOpen} handleClose={switchFilterPopup}>
        <MobileFilters
          materials={materials}
          tiles={initialSlabs}
          isRenderFinish={true}
          filtersState={state.filtersState}
          isSlab={true}
          isSaleSlabs={true}
          switchSelection={(filterType: FilterType, param: string) =>
            handleFilterUpdate(filterType, param, initialSlabs, updateState, state)
          }
          onClearAll={clearFilter}
          onClose={switchFilterPopup}
        />
      </Modal>
    </Layout>
  );
};

function formatData(
  slabs: Slab[],
  finishes: FinishInfo[],
  sortState: 'A - Z' | 'Z - A' | 'NEWEST',
): Slab[] {
  for (const slabInfo of slabs) {
    const finishId = slabInfo.finish?.id;
    if (finishId) {
      const finish = finishes.find((f) => f.id === finishId);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      slabInfo.finish.is_textured = finish.is_textured;
      slabInfo.finishes = [slabInfo.finish];
    } else {
      slabInfo.finishes = [];
    }

    slabInfo.colors = [
      slabInfo.color_primary,
      slabInfo.color_secondary,
      slabInfo.color_tertiary,
    ].filter((c) => c);
  }

  return slabs.sort(createSortFn(sortState));
}

export default SlabPage;
